.listNews_content{
    font-size: 24px;
    font-weight: 400;
    color: #0dafef;
    position: relative;
    padding: 0 0 10px;
    margin: 0 0 20px;
    line-height: 1.4;
    cursor: pointer;
    
    .listNews{
        margin: 0 0 30px;
        padding: 0 0 15px;
        border: 1px solid #e6e6e6;
        img{
            width: 100%;
            height: 206px;
            object-fit: cover   ;
        }

        p{
            font-size: 1.6rem;
            padding: 5px 10px;


            @include line-clam(4)
        }
    }
}

.DetailNews-content{
    font-size: 1.6rem;
    img{
        max-width:  100%;
        width: auto;
    }
    p{
        p{
            font-size: 1.6rem;
        }
    }
    a{
        font-size: 1.6rem;
    }
}
